import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-6 text-center" }
const _hoisted_3 = { class: "mt-12 d-grid" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "mt-4 fw-bold text-center text-success"
}
const _hoisted_6 = { class: "mt-4 text-center small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestPassword && _ctx.requestPassword(...args)), ["prevent"]))
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('auth.forgotPassword.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('auth.forgotPassword.body')), 1),
    _createVNode(_component_FormGroup, {
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      class: "mt-12",
      "error-key": "email",
      errors: _ctx.errors,
      label: _ctx.$t('auth.email'),
      name: "email",
      type: "email"
    }, null, 8, ["modelValue", "errors", "label"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        disabled: _ctx.loading
      }, _toDisplayString(_ctx.$t('auth.actions.sendRequest')), 9, _hoisted_4)
    ]),
    (_ctx.success)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('auth.forgotPassword.success')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_RouterLink, { to: {name: 'auth.login'} }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.actions.login')), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ], 32))
}