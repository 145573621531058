
import {defineComponent, ref} from 'vue';
import Errors from '@/library/model-collection/Errors';
import FormGroup from '@/components/common/form/FormGroup.vue';
import axios from 'axios';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        FormGroup,
    },
    setup() {
        const route = useRoute();

        return {
            email: ref(route.query.email),
            errors: ref(new Errors()),
            success: ref<boolean>(false),
            loading: ref<boolean>(false),
        };
    },
    methods: {
        async requestPassword() {
            this.loading = true;
            this.errors = new Errors();

            try {
                await axios.post('/auth/reset/password', {email: this.email});
            } catch (e: any) {
                this.success = false;

                if (!e.response || !e.response.data.errors) throw e;

                this.errors.setErrors(e.response.data.errors);

                return;
            } finally {
                this.loading = false;
            }

            this.success = true;
        },
    },
});
